import React, { useState } from 'react';
import { useEffect } from 'react';
import CloseSVG from '../../../../assets/svg/close.svg';
import Button from '../../../button';
import { useSelector } from 'react-redux';
import FormInput from '../../../form-input';
import Modal from '../../modal';
import {
  useDebitBranch,
  useFundBranch,
  useGetBranch,
} from '../../../../redux/human-resources/hook/branches';
import { useForm } from 'react-hook-form';
import { formValidation, formatNumber } from '../../../../utils/functions';
import { store } from '../../../../redux';

function FundBranch({ isOpen, closeModal, branch_id, payment_type }) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const { company } = useSelector((state) => state.companySlice);

  const { data: branch } = useGetBranch(branch_id);

  const [amount, setAmount] = useState(1001);

  const { mutateAsync: fundBranch, isLoading: fundLoading } = useFundBranch();

  const { mutateAsync: debitBranch, isLoading: debitLoading } =
    useDebitBranch();

  const submitForm = async () => {
    let payload = {
      id: branch_id,
      amount,
    };
    if (amount && amount >= 1001) {
      if (payment_type == 'debit') {
        await debitBranch(payload).then(() => {
          closeModal();
        });
      } else if (payment_type == 'credit') {
        await fundBranch(payload).then(() => {
          closeModal();
        });
      }
    } else {
      store.dispatch(
        setAlertsetAlert(
          true,
          'warning',
          'Amount cannot be less than NGN1,000',
        ),
      );
    }
  };

  return (
    <>
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col !max-h-[600px] overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>
                {payment_type == 'debit' ? 'Branch Debit' : 'Branch Funding'}
              </span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider mb-2' />
          <div className='flex flex-col justify-center mx-8 gap-2'>
            <div className='flex flex-col'>
              {payment_type == 'debit' ? (
                <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                  <span className='stat-card-header font-extrabold'>
                    BRANCH WALLET BALANCE{' '}
                    <span className='!text-[13px] text-color-black font-bold'>
                      (VFD - {branch?.account_number})
                    </span>
                  </span>
                  <span className='!text-[16px] text-color-black font-bold'>
                    {formatNumber(branch?.balance, 2)}
                  </span>
                </div>
              ) : (
                <div className='flex flex-col items-center p-4 py-3 border rounded-md bg-highlight'>
                  <span className='stat-card-header'>
                    COMPANY WALLET BALANCE{' '}
                    <span className='!text-[13px] text-color-black font-bold'>
                      (VFD - {company?.account_number} )
                    </span>
                  </span>
                  <span className='!text-[16px] text-color-black font-bold'>
                    {formatNumber(company?.total_balance, 2)}
                  </span>
                </div>
              )}
            </div>
            <hr className='divider mt-0 mb-2' />
            <div className='w-full'>
              <FormInput
                label={
                  payment_type == 'debit'
                    ? 'Debit Amount (Minimum NGN1,000)'
                    : 'Funding Amount (Minimum NGN1,000)'
                }
                placeholder={'Enter amount'}
                name='amount'
                type='number'
                defaultValue={amount}
                onInput={(e) => {
                  setAmount(e.target.value);
                }}
                inputRef={register(formValidation('number', true))}
                error={errors.amount}
                errorMessage={errors.amount && errors.amount.message}
              />
            </div>
            <div className='w-full mb-[20px]'>
              <Button
                text={payment_type == 'debit' ? 'DEBIT BRANCH' : 'FUND BRANCH'}
                type='submit'
                textClass={'!text-[15px'}
                loading={fundLoading || debitLoading}
                onClick={() => submitForm()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FundBranch;
