import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar';
import { OffcanvasBody, Offcanvas } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ProfileMenu from '../profile-menu';
import MenuSVG from '../../assets/svg/menu.svg';
import WhatsappSVG from '../../assets/svg/whatsapp.svg';
import {
  toggleSidebar,
  updateModalStatus,
} from '../../redux/components/components-slice';
import { useHistory, useLocation } from 'react-router-dom';
import CollapsingIcon from '../collapsing-icon';
import NoSubscriptionModal from '../modal/hr-section/subscription/subscription-notification';
import UpdateUserMobileModal from '../modal/update-user-mobile';
import { store } from '../../redux';
import classNames from 'classnames';
import AddPINModal from '../modal/add-pin';
import Button from '../button';
import SwitchSectionModal from '../modal/switch-section';
import moment from 'moment';

const DashboardLayout = ({ children, title }) => {
  const dispatch = useDispatch();
  const { isSidebarOpen, isModalOpen } = useSelector(
    (state) => state.componentsSlice,
  );
  const { section } = useSelector((state) => state.sectionSlice);
  const { user } = useSelector((state) => state.userSlice);

  const sidebarWidth = '255px';

  const [currentSection, setCurrentSection] = useState('');
  const location = useLocation();

  const [showNoSubModal, setShowNoSubModal] = useState(false);
  const [showUserMobileModal, setShowUserMobileModal] = useState(false);
  const [showSwitchModule, setShowSwitchModule] = useState(false);

  const [showTransactionPIN, setShowTransactionPIN] = useState(false);

  useEffect(() => {
    dispatch(updateModalStatus(false));
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.transaction_pin_status !== 1) {
        setShowTransactionPIN(true);
      }
      setCurrentSection(user.section);
    }
  }, [user]);

  useEffect(() => {
    if (section && user) {
      // console.log(moment().isBefore(moment(user.hr_subscription.end_date)));
      // console.log(user.hr_subscription.end_date);
      if (user.mobile) {
        if (
          section.name === 'core hr' &&
          location?.pathname !== '/settings/plans-billings'
        ) {
          if (
            moment().isAfter(moment(user.hr_subscription.end_date)) &&
            user.hr_subscription.status !== 1
          ) {
            store.dispatch(updateModalStatus(true));
            setShowNoSubModal(true);
          }
        } else {
          setShowNoSubModal(false);
        }
      } else {
        store.dispatch(updateModalStatus(true));
        setShowUserMobileModal(true);
      }
    }
  }, [section, user]);

  const history = useHistory();

  const handleSidebarToggle = () => {
    dispatch(toggleSidebar());
  };

  return (
    <div className='relative'>
      <div>
        <Offcanvas
          toggle={() => handleSidebarToggle()}
          isOpen={isSidebarOpen}
          style={{ width: sidebarWidth }}
        >
          <OffcanvasBody className='!p-0'>
            <Sidebar />
          </OffcanvasBody>
        </Offcanvas>
      </div>
      {showUserMobileModal ? (
        <UpdateUserMobileModal
          isOpen={showUserMobileModal}
          closeModal={() => {
            setShowUserMobileModal();
          }}
        />
      ) : null}
      {showNoSubModal ? (
        <NoSubscriptionModal
          isOpen={showNoSubModal}
          setShowNoSubModal={setShowNoSubModal}
          closeModal={() => setShowNoSubModal(false)}
        />
      ) : null}
      {showTransactionPIN ? (
        <AddPINModal
          isOpen={showTransactionPIN}
          closeModal={() => setShowTransactionPIN(false)}
        />
      ) : null}
      {showSwitchModule ? (
        <SwitchSectionModal
          isOpen={showSwitchModule}
          closeModal={() => setShowSwitchModule(false)}
          currentSection={currentSection}
        />
      ) : null}
      <div className='flex flex-row max-h-screen'>
        <div className='hidden lg:flex md:flex !min-w-[255px] h-screen inset-0'>
          <Sidebar />
        </div>
        <div className='w-full flex flex-col bg-secondary-2-light overflow-auto'>
          <div className='!opacity-100'>
            <div className='ml-0 bg-white h-[65px] w-full flex items-center justify-between md:pl:0 md:px-[30px] md:border-l-2 border-secondary-2-light '>
              <div className='flex flex-row items-center pl-4 md:pl-0'>
                <button onClick={() => handleSidebarToggle()}>
                  <img src={MenuSVG} alt='menu' className='flex lg:hidden' />
                </button>
                {location.pathname !== '/dashboard' ? (
                  <button
                    className='hidden md:block'
                    onClick={() => history.goBack()}
                  >
                    <CollapsingIcon
                      defaultPosition='right'
                      isopen={true}
                      dashboard={true}
                    />
                  </button>
                ) : null}
                <span
                  className={classNames('hidden md:block header-4 pl-3', {
                    'text-secondary-2': section.name === 'payroll',
                    'text-hr-primary-1': section.name === 'core hr',
                    'text-spend-primary-1': section.name === 'spend',
                  })}
                >
                  {title}
                </span>
              </div>
              <div className='flex flex-row md:-mr-3 mr-4 items-center justify-center gap-16'>
                <Button
                  text='Switch Module'
                  type='button'
                  theme='primary'
                  className='flex gap-2 h-[35px] w-fit p-2'
                  rightIcon={'change'}
                  textClass={'!text-[12px] font-medium'}
                  onClick={() => {
                    setShowSwitchModule(true);
                  }}
                />
                <div className='hidden md:flex'>
                  <ProfileMenu />
                </div>
              </div>
            </div>
            {/* Page Component */}
            <div className='w-full h-full p-8'>{children}</div>
          </div>
        </div>
      </div>
      <div className='flex gap-2 absolute  bottom-4 right-6 items-center'>
        <a
          href='https://web.whatsapp.com/send?phone=2349117334270&text=Hi%20there!'
          target='_blank'
          className='hover:scale-110 duration-300'
        >
          <img src={WhatsappSVG} alt='menu' />
        </a>
        <span
          className='flex gap-[3px] z-auto bg-[#75B73B] items-center p-2 px-3 rounded-[30px] cursor-pointer text-[14px] font-bold hover:text-[15px] duration-300 text-white'
          onClick={() => {
            history.push('/contact-us');
          }}
        >
          Send us a message
        </span>
      </div>
    </div>
  );
};

export default DashboardLayout;
